require('../css/font.css');
require('../css/style.css');

window.addEventListener('DOMContentLoaded', run);

async function run(e) {

    document.body.addEventListener('click', e => {

        if (e.target.classList.contains('scroll-to-link')) {
            scrollTo(e);
        }
    });

    const tooltips = new Tooltips();
    tooltips.start();
}

function scrollTo(e) {

    e.preventDefault();
    const link = e.target;
    const id = link.href.slice(link.href.lastIndexOf('#') + 1);
    const element = document.getElementById(id);
    element.scrollIntoView({ behavior: 'smooth' });

    if (link.dataset.tab) {
        document.getElementById(link.dataset.tab).click();
    }
}

function isMobile() {
    const detector = document.querySelector('.mobile-detector');
    const styles = window.getComputedStyle(detector);
    return styles.getPropertyValue('display') === 'none';
}


class Tooltips {

    constructor(params = {}) {

        this.default = {
            selector: '.xtooltip'
        };

        this.tooltips = Array.from(document.querySelectorAll(params.selector || this.default.selector));
    }

    start() {

        this.tooltips.forEach((tooltip, i) => {

            const bubble = document.createElement('div');
            bubble.textContent = tooltip.dataset.tooltip;
            bubble.classList.add('tooltip-bubble');

            tooltip.bubble = bubble;

            document.body.appendChild(bubble);
            this.hideTooltip(tooltip);

            tooltip.addEventListener('mouseenter', e => {
                this.showTooltip(e.target);
            });

            tooltip.addEventListener('mouseleave', e => {
                this.hideTooltip(e.target);
            });

            tooltip.addEventListener('click', e => {
                if (isMobile()) {
                    this.toggleTooltip(e.target);
                }
            });
        });
    }

    showTooltip(element) {

        const { bubble } = element;

        bubble.style.opacity = 1;
        bubble.style.top = (element.offsetTop - (bubble.clientHeight + 16)) + 'px';
        bubble.style.left = (element.offsetLeft + (bubble.clientWidth / 3)) + 'px';
    }

    hideTooltip(element) {
        element.bubble.style.opacity = 0;
    }

    toggleTooltip(element) {
        element.bubble.style.opacity == 0
            ? this.showTooltip(element)
            : this.hideTooltip(element);
    }
}